import React, { useState } from "react";
import css from "./style.module.scss";

import { Container, Header, Icon } from "../../..";
import EnrollmentSummary from "../enrollment-summary";
import { connect } from "react-redux";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/confirm-email.json";
import { Button } from "antd";

const ConfirmApplicationRaw = (props) => {
  return (
    <Container className={css.mainContainer}>
      <Container className={css.header}>
        <Container className={css.logoContainer}>
          <Icon fill="#fff" icon="enrollment-logo" />
        </Container>

        <Container className={css.stepsContainer}></Container>
      </Container>

      <Container className={css.contentContainer}>
        <Container className={css.lottieContainer}>
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 144, height: 144 }}
          />
        </Container>
        <Container className={css.congratulationsText}>
          Congratulations ! You're almost done!
        </Container>
        <Container className={css.confirmApplicationText}>
          A confirmation email was sent to{" "}
          {JSON.parse(localStorage.getItem("params")).email}
        </Container>

        <Container className={css.emailSentText}>
          You must review the <span>Terms and Conditions</span> of your policy,
          Enter your <span>Full Name</span> and select{" "}
          <span>Sign Authorization</span> in order to complete your application
          and access your policy documents.
        </Container>

        <Container className={css.confirmApplicationButton}>
          <Button
            type="primary"
            onClick={() => {
              window.open(props.memberPortalUrl, "_blank");
            }}
          >
            Confirm Application
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmApplication: (payload) =>
      dispatch({ type: `CONFIRM_APPLICATION`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmApplicationRaw);
