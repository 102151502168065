import React, { useState, useEffect, isValidElement } from "react";

import {
  Container,
  ConversionSlider,
  DatePicker,
  DoubledInputSlider,
  Icon,
  InputSlider,
  TextInput,
  Select,
} from "../../../";

import { OfferCard } from "../../../quotes-panel/components";

import css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button } from "antd";
import { connect } from "react-redux";

import moment from "moment";
import axios from "axios";
import { handleInputChange } from "../../../../utils";
import { formatCurrency } from "../../../../utils";

import _ from "lodash";

const AdditionalInfoRaw = (props) => {
  const [loading, setLoading] = useState(true);

  const effectiveDate = props.effectiveDate;

  const termsLength = props.terms;

  const specialRates = props.specialRates || {};

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);
  const params = JSON.parse(localStorage.getItem("params"));

  params.dependents = params.dependents ? JSON.parse(params.dependents) : [];
  const spouse = props.applicants.spouse;
  const dependents = props.applicants.dependents;

  const getUpdatedValues = async (payload) => {
    const response2 = await axios({
      method: "post",
      url: `${props.baseUrl}/v1/enrollment/national-general/${params.quote_id}/accurate-quote`,
      data: {
        zip_code: params.zip_code,
        state: params.state,
        effective_date: payload.effectiveDate
          .toDate()
          .toISOString()
          .split("T")[0],
        term_length: payload.termsLength,
        primary_applicant: {
          gender: params.gender,
          date_of_birth: moment()
            .add(-(Number(params.age) * 366), "days")
            .toDate()
            .toISOString()
            .split("T")[0],
          uses_tobacco: params.uses_tobacco,
          ...(specialRates
            ? {
                has_prior_coverage:
                  specialRates.priorCoverage == 1 ? true : false,
                rate_tier:
                  specialRates.prefferedRate == 1 ? "Preferred" : "Standard",
              }
            : {}),
        },
        spouse: props.applicants.spouse
          ? {
              ...props.spouse,
              date_of_birth: props.spouse.date_of_birth
                ? props.spouse.date_of_birth.toISOString().split("T")[0]
                : moment()
                    .add(-(Number(spouse.age) * 366), "days")
                    .toDate()
                    .toISOString()
                    .split("T")[0],
              gender: spouse.gender,
              uses_tobacco: spouse.uses_tobacco ? true : false,
              ...(specialRates
                ? {
                    has_prior_coverage:
                      specialRates.priorCoverage == 1 ? true : false,
                    rate_tier:
                      specialRates.prefferedRate == 1
                        ? "Preferred"
                        : "Standard",
                  }
                : {}),
            }
          : null,
        dependents: dependents
          ? dependents.map((d) => {
              return {
                ...d,
                ...(specialRates
                  ? {
                      has_prior_coverage:
                        specialRates.priorCoverage == 1 ? true : false,
                      rate_tier:
                        specialRates.prefferedRate == 1
                          ? "Preferred"
                          : "Standard",
                    }
                  : {}),
              };
            })
          : [],
        plans: JSON.parse(params.plans).map((p) => {
          return {
            plan_key: p.plan_key.value,
            insurance_network: p.insurance_network.value,
          };
        }),
        plan_keys: JSON.parse(params.plans).map((p) => {
          return p.plan_key.value;
        }),
      },
      headers: {
        "x-apollo-quotes-source": props.agentMetadata.id,
      },
    });

    localStorage.setItem("plans", JSON.stringify(response2.data.plans));

    props.updateCartValues(response2.data);

    // localStorage.setItem("params", JSON.stringify(
    //   {
    //     ...params,
    //     plans: response2.data.plans
    //   }
    // ))

    props.setAdditionalInfo({
      key: "terms",
      value: payload.termsLength,
    });

    props.setAdditionalInfo({
      key: "effectiveDate",
      value: payload.effectiveDate,
    });
  };

  const meetPreferredPremiumRequirements = () => {
    if (params.gender == "Male" && props.weight > 240) return false;
    if (params.gender == "Female" && props.weight > 190) return false;
    if (spouse && props.spouse) {
      if (spouse.gender == "Male" && props.spouse.weight > 240) return false;
      if (spouse.gender == "Female" && props.spouse.weight > 190) return false;
    }

    return true;
  };

  const isValid = () => {
    let spouseValid = true;
    if (
      spouse &&
      (!spouse.first_name ||
        !spouse.last_name ||
        !spouse.date_of_birth ||
        !spouse.feet ||
        !spouse.weight)
    ) {
    }

    let dependentsValid = true;

    dependents.forEach((d) => {
      if (!d.first_name || !d.last_name || !d.date_of_birth) {
        dependentsValid = false;
      }
    });

    if (specialRates && specialRates.prefferedRate === 1) {
      if (!meetPreferredPremiumRequirements()) {
        return true;
      }
    }

    return (
      !props.weight ||
      !props.feet ||
      !spouseValid ||
      !dependentsValid ||
      !props.address ||
      !props.city
    );
  };

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#27A0FF"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            Main Applicant:
          </Container>
          <Container>
            <Container>
              <Container className={css.birthdateContainer}>
                <DatePicker
                  value={props.date_of_birth}
                  defaultPickerValue={moment().add(
                    -(Number(params.age) * 366),
                    "days"
                  )}
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
              <InputSlider
                min={0}
                max={
                  specialRates.prefferedRate === 1
                    ? params.gender == "Male"
                      ? 240
                      : 190
                    : 900
                }
                setValue={(v) => {
                  props.setAdditionalInfo({ key: "weight", value: v });
                }}
                unit="Lbs"
                label="Weight:"
                value={props.weight}
                icon="weight"
              />
            </Container>
            <Container>
              <DoubledInputSlider
                max={9}
                setValue={(v) => {
                  props.setAdditionalInfo({
                    key: "feet",
                    value: Math.floor(v),
                  });
                  props.setAdditionalInfo({
                    key: "inches",
                    value: Math.round((v - Math.floor(v)) * 12),
                  });
                }}
                setValue1={(v) => {
                  props.setAdditionalInfo({
                    key: "feet",
                    value: v,
                  });
                }}
                setValue2={(v) => {
                  props.setAdditionalInfo({
                    key: "inches",
                    value: v,
                  });
                }}
                value1={props.feet}
                value2={props.inches}
                unit="Ft"
                unit2="Inches"
                label="Height:"
                icon="height"
              />
            </Container>
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#27A0FF"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          first_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.first_name}
                    focusedlabel="Fist Name"
                    placeholder="First Name"
                  />
                </Container>
                <Container className={css.lastNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          last_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.last_name}
                    placeholder="Last Name"
                    focusedlabel="Last Name"
                  />
                </Container>
                <Container className={css.birthdateContainer}>
                  <DatePicker
                    defaultPickerValue={moment().add(
                      -(Number(spouse.age) * 366),
                      "days"
                    )}
                    value={props.spouse.date_of_birth}
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
                <InputSlider
                  min={0}
                  max={
                    specialRates.prefferedRate === 1
                      ? props.spouse.gender == "Male"
                        ? 240
                        : 190
                      : 900
                  }
                  setValue={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: { ...props.spouse, weight: v },
                    });
                  }}
                  unit="Lbs"
                  label="Weight"
                  value={props.spouse.weight}
                  icon="weight"
                />
              </Container>
              <Container>
                <DoubledInputSlider
                  max={9}
                  setValue={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: {
                        ...props.spouse,
                        feet: Math.floor(v),
                        inches: Math.round((v - Math.floor(v)) * 12),
                      },
                    });
                  }}
                  setValue1={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: {
                        ...props.spouse,
                        feet: v,
                      },
                    });
                  }}
                  setValue2={(v) => {
                    props.setAdditionalInfo({
                      key: "spouse",
                      value: {
                        ...props.spouse,
                        inches: v,
                      },
                    });
                  }}
                  unit="Ft"
                  unit2="Inches"
                  label="Height"
                  value1={props.spouse.feet}
                  value2={props.spouse.inches}
                  icon="height"
                />
              </Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#27A0FF"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                Dependent {ind + 1}:
              </Container>
              <Container>
                <Container>
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      value={dependents[ind].first_name}
                      before="First Name"
                      onChange={(e) => {
                        dependents[ind].first_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.lastNameInputContainer}>
                    <TextInput
                      value={dependents[ind].last_name}
                      before="Last Name"
                      onChange={(e) => {
                        dependents[ind].last_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      value={dependents[ind].date_of_birth}
                      before={"Date Of Birth"}
                      defaultPickerValue={moment().add(
                        -(Number(dep.age) * 366),
                        "days"
                      )}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;

                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
      <Container className={css.lastInputs}>
        <Container className={css.householdContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon icon="building" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Complete Household Address:
              </Container>
            </Container>
          </Container>
          <Container className={css.addressInputs}>
            <Container className={css.streetAndNumberContainer}>
              <Container className={css.addressStreetNameInput}>
                <TextInput
                  value={props.address}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "address",
                      value: handleInputChange(e),
                    });
                  }}
                  placeholder="Street Address"
                  focusedlabel="Street Address"
                />
              </Container>
              <Container className={css.addressNumberInput}>
                <TextInput
                  placeholder="APT.#, Unit"
                  value={props.apt}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "apt",
                      value: handleInputChange(e),
                    });
                  }}
                />
              </Container>
            </Container>

            <Container className={css.addressCityInput}>
              <TextInput
                value={props.city}
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "city",
                    value: handleInputChange(e),
                  });
                }}
                placeholder="City"
                focusedlabel="City"
              />
            </Container>
          </Container>
        </Container>
        <Container className={css.coverageContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon icon="heart-schedule" />
            </Container>
            <Container
              style={{ marginLeft: "2px" }}
              className={css.householdTitle}
            >
              Select the Coverage Start Date:
            </Container>
          </Container>

          <Container className={css.coverageDates}>
            {props.availableTerms.length > 0 && (
              <Container className={css.termLengthInput}>
                <Select
                  value={termsLength}
                  size="large"
                  before="Policy Term Length"
                  onChange={(e) => {
                    getUpdatedValues({
                      effectiveDate,
                      termsLength: e,
                    });
                  }}
                  options={props.availableTerms.map((m) => {
                    return {
                      label: m.name,
                      value: m.id,
                    };
                  })}
                />
              </Container>
            )}
            <Container className={css.coverageDateInput}>
              <DatePicker
                disabledDate={(m) => {
                  const now = moment(new Date());
                  if (now >= m || now.add(45, "day") <= m) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                before="Coverage Start Date"
                className={css.datePicker}
                onChange={(v) => {
                  getUpdatedValues({
                    effectiveDate: v,
                    termsLength,
                  });
                }}
                value={effectiveDate}
              />
            </Container>
          </Container>
        </Container>
      </Container>

      {specialRates.prefferedRate === 1 && !meetPreferredPremiumRequirements() && (
        <Container className={css.weightNotAllowedContainer}>
          <Container className={css.weightNotAllowed}>
            <Container className={css.weightNotAllowedIconAndText}>
              <Container className={css.weightNotAllowedIcon}>
                <Icon
                  style={{ width: "42px", height: "42px" }}
                  icon="information"
                />
              </Container>
              <Container className={css.weightNotAllowedText}>
                Based on the weight entered, you do not qualify for a preferred
                rate. We will continue to enroll at the standard rate.
              </Container>
            </Container>
            <Container>
              <Container className={css.agreeButton}>
                <Button
                  onClick={async () => {
                    const response2 = await axios({
                      method: "post",
                      url: `${props.baseUrl}/v1/enrollment/national-general/${params.quote_id}/accurate-quote`,
                      data: {
                        zip_code: params.zip_code,
                        state: params.state,
                        effective_date: effectiveDate
                          .toDate()
                          .toISOString()
                          .split("T")[0],
                        term_length: termsLength,
                        primary_applicant: {
                          gender: params.gender,
                          date_of_birth: moment()
                            .add(-(Number(params.age) * 366), "days")
                            .toDate()
                            .toISOString()
                            .split("T")[0],
                          uses_tobacco: params.uses_tobacco,
                          ...(specialRates
                            ? {
                                has_prior_coverage:
                                  specialRates.priorCoverage == 1
                                    ? true
                                    : false,
                                rate_tier: "Standard",
                              }
                            : {}),
                        },
                        spouse: props.applicants.spouse
                          ? {
                              ...props.spouse,
                              date_of_birth: props.spouse.date_of_birth
                                ? props.spouse.date_of_birth
                                    .toISOString()
                                    .split("T")[0]
                                : moment()
                                    .add(-(Number(spouse.age) * 366), "days")
                                    .toDate()
                                    .toISOString()
                                    .split("T")[0],
                              gender: spouse.gender,
                              uses_tobacco: spouse.uses_tobacco ? true : false,
                              ...(specialRates
                                ? {
                                    has_prior_coverage:
                                      specialRates.priorCoverage == 1
                                        ? true
                                        : false,
                                    rate_tier: "Standard",
                                  }
                                : {}),
                            }
                          : null,
                        dependents: dependents
                          ? dependents.map((d) => {
                              return {
                                ...d,
                                ...(specialRates
                                  ? {
                                      has_prior_coverage:
                                        specialRates.priorCoverage == 1
                                          ? true
                                          : false,
                                      rate_tier: "Standard",
                                    }
                                  : {}),
                              };
                            })
                          : [],
                        plans: JSON.parse(params.plans).map((p) => {
                          return {
                            plan_key: p.plan_key.value,
                            insurance_network: p.insurance_network.value,
                          };
                        }),
                        plan_keys: JSON.parse(params.plans).map((p) => {
                          return p.plan_key.value;
                        }),
                      },
                      headers: {
                        "x-apollo-quotes-source": props.agentMetadata.id,
                      },
                    });

                    console.log("res", response2);
                    localStorage.setItem(
                      "plans",
                      JSON.stringify(response2.data.plans)
                    );
                    props.updateSpecialRates({
                      priorCoverage: specialRates.priorCoverage,
                      prefferedRate: 0,
                    });
                  }}
                  shape="round"
                >
                  I Agree
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}

      <Container className={css.priceDisclaimer}>
        *The monthly payment of $
        {JSON.parse(localStorage.getItem("plans")).reduce((a, b) => {
          return a + b.premium.value;
        }, 0)}{" "}
        is consistent with the selected date and term length. <br />
        By setting another date the value will be changed automatically
      </Container>
      <Container className={css.buttonsContainer}>
        <Container className={css.previousButton}>
          <Button
            onClick={() => {
              props.previous && props.previous();
            }}
            type="primary"
            shape="round"
          >
            Previous
          </Button>
        </Container>
        <Container className={css.nextButton}>
          <Button
            disabled={isValid()}
            onClick={() => {
              props.next && props.next();
            }}
            type="primary"
            shape="round"
          >
            Next
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
  specialRates,
}) => {
  return {
    applicants,
    agentMetadata,
    baseUrl,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
    availableTerms: enrollment.nationalGeneral.availableTerms,
    specialRates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartValues: (payload) => {
      dispatch({ type: `UPDATE_CART_VALUES`, payload });
    },
    updateSpecialRates: (payload) =>
      dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
