import React, { useState } from "react";

import { Container, Icon, ProductCard, ProductCardList } from "../../../";

import { OfferCard } from "../../../quotes-panel/components";

import css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/lottie.json";
import { getJSDocParameterTags } from "typescript";
import { connect } from "react-redux";

const sjson = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const EnrollmentSummary = (props) => {
  const params = JSON.parse(localStorage.getItem("params"));
  const enrollmentFee =
    props.enrollment.nationalGeneral.fees.filter(
      (f) => f.label == "Enrollment Fee"
    ).length == 1
      ? 35
      : 0;

  return (
    <Container className={css.summaryContainer}>
      <Container className={``}>
        <Container className={css.selectedProductsAndPersonalInfo}>
          <Container className={css.personalInfoContainer}>
            <Container>
              <Container className={css.checkoutTitle}>
                Review and Complete your application:
              </Container>
              <Container className={css.iconAndSummary}>
                <Container className={css.icon}>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 240, height: 240 }}
                  />
                </Container>
              </Container>
            </Container>
            <Container className={css.personalInfoCard}>
              <Container className={css.personalInfoTitle}>
                Personal Info:
              </Container>
              <Container className={css.personalInfoValues}>
                <Container className={css.personalInfoValue}>
                  <span>Name: </span>
                  {params.firstName || "John"} {params.lastName || "Diego"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Age: </span>
                  {params.age || "23"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Gender: </span>
                  {params.gender || "Male"}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Tobacco: </span>
                  {params.usesTobacco ? "Yes" : "No"}
                </Container>

                <Container className={css.personalInfoValue}>
                  <span>State: </span>
                  {sjson[params.state]}
                </Container>
                <Container className={css.personalInfoValue}>
                  <span>Phone: </span>
                  {params.phone || "(212) 1234567"}
                </Container>

                <Container
                  className={`${css.personalInfoValue} ${css.emailValue}`}
                >
                  <span>Email: </span>
                  {params.email || "diego@apollo-insurance.com"}
                </Container>
              </Container>
            </Container>
          </Container>
          <Container className={css.selectedProductsContainer}>
            <Container>
              <Container className={css.selectedProductsTitle}>
                Selected Products:
              </Container>
            </Container>
            <Container className={css.productCards}>
              {JSON.parse(localStorage.getItem("plans")).map((p) => {
                const { info } = p;
                let iconProps;
                if (info && info.value == "Association")
                  iconProps = { icon: "association", fill: "#F7971C" };
                if (info && info.value == "NHICSupplemental")
                  iconProps = { icon: "shield-filled", fill: "#F7971C" };
                if (info && info.value == "STM")
                  iconProps = { icon: "shield-outlined", fill: "#F7971C" };
                if (info && info.value == "Basic")
                  iconProps = { icon: "s-outlined", fill: "#1B75BC" };
                if (info && info.value == "Crown")
                  iconProps = { icon: "crown", fill: "#1B75BC" };
                if (info && info.value == "Enhanced")
                  iconProps = { icon: "s-filled", fill: "#1B75BC" };
                if (info && info.value == "Short Term Health Insurance")
                  iconProps = { fill: "#575988", icon: "short-term" };
                if (info && info.value == "Dental Insurance")
                  iconProps = { icon: "dental-insurance", fill: "#053555" };
                if (info && info.value == "HealthiestYou")
                  iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
                if (info && info.value == "Hospital Indemnity")
                  iconProps = { icon: "hospital-indemnity", fill: "#FF3D3D" };
                if (info && info.value == "Supplemental Indemnity")
                  iconProps = { icon: "supplemental", fill: "#F3BF38" };
                if (info && info.value == "HealthiestYou")
                  iconProps = { icon: "healthiest-you", fill: "#77C3FF" };
                if (info && info.value == "Term Life")
                  iconProps = { icon: "term-life", fill: "#1B75BC" };
                if (info && info.value == "Vision")
                  iconProps = { icon: "vision", fill: "#1C4C99" };
                if (info && info.value == "Accident")
                  iconProps = { icon: "accident", fill: "#F7971C" };
                if (info && info.value == "Critical Illness")
                  iconProps = { icon: "critical-illness", fill: "#77C3FF" };
                if (p.plan_name.value == "Aspire Dental")
                  iconProps = { icon: "aspire-dental", fill: "#77C3FF" };

                return (
                  <Container
                    key={"kky" + p.plan_name.value}
                    className={css.planCard}
                  >
                    <Container className={css.planCardIcon}>
                      <Icon
                        style={{ width: "48px", height: "48px" }}
                        {...iconProps}
                      />
                    </Container>
                    <Container className={css.planCardInfo}>
                      <Container className={css.planCardCarrierName}>
                        {p.carrier.value}
                      </Container>
                      <Container className={css.planCardName}>
                        {p.plan_name.value}
                      </Container>
                    </Container>
                    <Container className={css.planCardPremium}>
                      ${p.premium.value?.toFixed(2)}
                    </Container>
                  </Container>
                );
              })}
              <Container className={css.payments}>
                <Container className={css.applicants}>
                  <Container className={css.applicantLabel}>
                    Applicants:{" "}
                  </Container>
                  <Container className={css.applicantsDropDowns}>
                    <Container className={css.adultContainer}>
                      {props.applicants.spouse ? "2 Adults" : "1 Adult"}
                    </Container>
                    <Container className={css.dependentContainer}>
                      {props.applicants.dependents.length} Dependents
                    </Container>
                  </Container>
                </Container>
                <Container className={css.taxes}>
                  {props.enrollment.nationalGeneral.fees.map((f) => {
                    return (
                      <Container
                        key={"k-" + f.label + f.value}
                        className={css.tax}
                      >
                        <Container className={css.taxName}>{f.label}</Container>
                        <Container className={css.taxValue}>
                          ${f.value.toFixed(2)}
                        </Container>
                      </Container>
                    );
                  })}
                </Container>
                {/* <Container className={ css.total}>
                  <Container className={ css.totalLabel }>Today's Payment</Container>
                <Container>${(props.enrollment.nationalGeneral.monthlyValue + enrollmentFee).toFixed(2) } </Container>
              </Container>
              <Container className={ css.monthlyTotal}>
                <Container className={ css.monthlyTotalLabel }>Monthly Payment</Container>
                <Container>${ props.enrollment.nationalGeneral.monthlyValue.toFixed(2)}</Container>
              </Container> */}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  quote_id,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    quote_id,
    enrollment,
    ...enrollment.nationalGeneral.additionalInfo,
  };
};

export default connect(mapStateToProps)(EnrollmentSummary);
